<template>
  <b-row>
    <b-col cols="12" v-if="can('read/laporan-sppd')">
      <b-card title="Laporan SPPD">
        <b-row class="pb-2">
          <b-col class="mb-1">
            <b-button
              v-if="can('filter/laporan-sppd')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-0"
              @click="show=true"
            >
              Filter
            </b-button>
            <b-button
              v-if="can('print/laporan-sppd')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="ml-2"
              @click="exportData()"
              :disabled="totalRows == 0"
            >
              Export Data
            </b-button>
          </b-col>

          <b-col md="4" xs="12">
            <b-form @submit.prevent="handleSearch">
              <b-input-group size="8">
                <b-form-input
                  id="filterInput"
                  v-model="search"
                  type="search"
                  :placeholder="'Cari disini...'"
                />
                <b-input-group-append>
                  <b-button :disabled="!search" @click="search = ''">
                    Hapus
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>
      
        <b-table
          striped
          hover
          responsive
          show-empty
          class="position-relative"
          primary-key="email"
          :per-page="perPage"
          :current-page="currentPage"
          :items="getItems"
          :fields="fields"
          :busy="busy"
          ref="tableFe"
        >
          <template #table-busy>
            <div class="text-center my-3">
              <!-- <b-spinner small type="grow" label="Loading..."></b-spinner> -->
              <div>Loading data, please wait...</div>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          
          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(name)="data">
            {{ data.item.nippos }} / {{ data.item.name }}
          </template>

          <template #cell(daily_trip_money)="data">
            <span class="text-capitalize d-block" style="text-align: end;">
              {{ data.item.daily_trip_money | currency }}
            </span>
          </template>

          <template #cell(total_transportation)="data">
            <span class="text-capitalize d-block" style="text-align: end;">
              {{ data.item.total_transportation | currency }}
            </span>
          </template>

          <template #cell(total_accomodation)="data">
            <span class="text-capitalize d-block" style="text-align: end;">
              {{ data.item.total_accomodation | currency }}
            </span>
          </template>

          <template #cell(total)="data">
            <span class="text-capitalize d-block" style="text-align: end;">
              {{ data.item.total | currency }}
            </span>
          </template>

          <template #cell(pph21)="data">
            <span class="text-capitalize d-block" style="text-align: end;">
              {{ data.item.pph21 | currency }}
            </span>
          </template>

          <template #cell(day_remainder)="data">
            {{ data.item.day_remainder + ' hari' }}
          </template>

          <template #cell(mitra_accomodation_name)="data">
            {{ data.item.mitra_accomodation_name == null ? "-" : data.item.mitra_accomodation_name }}
          </template>

          <template #cell(mitra_transportation_name)="data">
            {{ data.item.mitra_transportation_name == null ? "-" : data.item.mitra_transportation_name }}
          </template>

          <!-- A virtual column -->
          <template #cell(Aksi)="data">
            <div style="min-width: 270px">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                v-b-popover.hover.bottom="'Detail'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-popover.hover.bottom="'Approve'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                v-b-popover.hover.bottom="'Ubah'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                v-b-popover.hover.bottom="'Hapus'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="confirmText(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>

            </div>
          </template>
        </b-table>

        <span v-if="totalRows == 1" style="margin: 1rem"></span>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
                id="perPageSelect"
                v-model="perPage"
                @change="changePerPage()"
                size="sm"
                inline
                :options="pageOptions"
            />
          </b-form-group>

          <div>
            Menampilkan {{ from }} hingga {{ to }} dari {{ totalRows }} entri
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

            <!-- Modal -->
            <b-modal
              id="modal-filter"
              v-model="show"
              title="Filter"
              size="md"
              cancel-variant="outline-secondary"
            >
            <div class="hidden-scrollbar box-filter-laporan-sppd">
              <div class='inner'>
                <b-form @submit.prevent="handleSubmit(filter)" v-if="role_name == 'Admin Nasional'">
                  <b-row>
                    <b-col md="6">
                      <label>Type Project</label>
                      <b-form-radio-group
                        id="project"
                        v-model="selectedProject"
                        :options="selectProjectOption"
                        name="radio-options"
                      ></b-form-radio-group>
                    </b-col>
                    <b-col md="6">
                      <label>SUB DIREKTORAT</label>
                      <multiselect
                        placeholder="Cari Sub Direktorat"
                        v-model="subdirData"
                        label="namabagian_child"
                        track-by="kodebagian_child"
                        :options="subdirLists"
                        @input="changeSubdir"
                      ></multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>REGIONAL</label>
                      <multiselect 
                        v-model="selectRegional"
                        name="idwilayah"
                        label="nmwilayah"
                        track-by="idwilayah" 
                        placeholder="Cari Regional"
                        :options="regional"
                        class="single-select filter-laporan-sppd"
                        :max-height="600"
                        @input="selectedUPT(selectRegional.idwilayah)">
                        <span slot="noResult">Maaf UPT tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>KC</label>
                      <multiselect 
                        v-model="selectKC"
                        name="nopend"
                        label="namaktr"
                        track-by="nopend" 
                        placeholder="Cari KC"
                        :options="kc"
                        class="single-select filter-laporan-sppd"
                        :max-height="600"
                        @input="selectedNIPPOS(selectKC.nopend)">
                        <span slot="noResult">Maaf KC tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>KCU</label>
                      <multiselect 
                        v-model="selectKCU"
                        name="nopend"
                        label="namaktr"
                        track-by="nopend" 
                        placeholder="Cari KCU"
                        :options="kcu"
                        class="single-select filter-laporan-sppd"
                        :max-height="600"
                        @input="selectedNIPPOSKCU(selectKCU.nopend)">
                        <span slot="noResult">Maaf KCU tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>NIPPOS</label>
                      <multiselect 
                        v-model="selectNIPPOS"
                        name="nippos"
                        label="nippos"
                        track-by="nippos" 
                        placeholder="Cari NIPPOS"
                        :options="nippos"
                        class="single-select filter-laporan-sppd"
                        :max-height="600"
                        @input="selectedSPPD(selectNIPPOS.nippos)">
                        <span slot="noResult">Maaf NIPPOS tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>No SPPD</label>
                      <v-select v-model="model.sppd_number" :options="sppd" :reduce="(item) => item.sppd_number" label="sppd_number" class="key-filter" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <label>Mitra Transportasi</label>
                      <multiselect 
                        v-model="selectedMitraTransportation"
                        name="id"
                        label="agen_name"
                        track-by="id" 
                        placeholder="Cari Mitra Transportasi"
                        :options="mitraTransportasi"
                        class="single-select filter-laporan-sppd"
                        :max-height="600">
                        <span slot="noResult">Maaf mitra transportasi tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>Mitra Akomodasi</label>
                      <multiselect 
                        v-model="selectedAccomodation"
                        name="id"
                        label="agen_name"
                        track-by="id" 
                        placeholder="Cari Mitra Akomodasi"
                        :options="mitraAkomodasi"
                        class="single-select filter-laporan-sppd"
                        :max-height="600">
                        <span slot="noResult">Maaf mitra akomodasi tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="6">
                      <label>Tanggal Berangkat</label>
                      <br>
                      <date-picker
                        :firstDayOfWeek="1"
                        v-model="model.departure_date"
                        name="departure_date"
                        :lang="lang"
                        :format="formats"
                        value-type="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        ></date-picker>
                    </b-col>
                    <b-col md="6">
                      <label>Tanggal Kembali</label>
                      <br>
                      <date-picker
                        :firstDayOfWeek="1"
                        v-model="model.return_date"
                        name="return_date"
                        :lang="lang"
                        :format="formats"
                        value-type="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        ></date-picker>
                    </b-col>
                  </b-row>
                </b-form>
                <b-form @submit.prevent="handleSubmit(filter)" v-else-if="role_name == 'Admin Sppd' && scope== 'REGIONAL'">
                  <b-row>
                    <b-col md="6">
                      <label>KC</label>
                      <multiselect 
                        v-model="selectKC"
                        name="nopend"
                        label="namaktr"
                        track-by="nopend" 
                        placeholder="Cari KC"
                        :options="kc"
                        class="single-select filter-laporan-sppd"
                        :max-height="600"
                        @input="selectedNIPPOS(selectKC.nopend)">
                        <span slot="noResult">Maaf KC tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>KCU</label>
                      <multiselect 
                        v-model="selectKCU"
                        name="nopend"
                        label="namaktr"
                        track-by="nopend" 
                        placeholder="Cari KCU"
                        :options="kcu"
                        class="single-select filter-laporan-sppd"
                        :max-height="600"
                        @input="selectedNIPPOSKCU(selectKCU.nopend)">
                        <span slot="noResult">Maaf KCU tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>NIPPOS</label>
                      <multiselect 
                        v-model="selectNIPPOS"
                        name="nippos"
                        label="nippos"
                        track-by="nippos" 
                        placeholder="Cari NIPPOS"
                        :options="nippos"
                        class="single-select filter-laporan-sppd"
                        :max-height="600"
                        @input="selectedSPPD(selectNIPPOS.nippos)">
                        <span slot="noResult">Maaf NIPPOS tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>No SPPD</label>
                      <v-select v-model="model.sppd_number" :options="sppd" :reduce="(item) => item.sppd_number" label="sppd_number" class="key-filter" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <label>Mitra Transportasi</label>
                      <multiselect 
                        v-model="selectedMitraTransportation"
                        name="id"
                        label="agen_name"
                        track-by="id" 
                        placeholder="Cari Mitra Transportasi"
                        :options="mitraTransportasi"
                        class="single-select filter-laporan-sppd"
                        :max-height="600">
                        <span slot="noResult">Maaf mitra transportasi tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>Mitra Akomodasi</label>
                      <multiselect 
                        v-model="selectedAccomodation"
                        name="id"
                        label="agen_name"
                        track-by="id" 
                        placeholder="Cari Mitra Akomodasi"
                        :options="mitraAkomodasi"
                        class="single-select filter-laporan-sppd"
                        :max-height="600">
                        <span slot="noResult">Maaf mitra akomodasi tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="6">
                      <label>Tanggal Berangkat</label>
                      <br>
                      <date-picker
                        :firstDayOfWeek="1"
                        v-model="model.departure_date"
                        name="departure_date"
                        :lang="lang"
                        :format="formats"
                        value-type="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        ></date-picker>
                    </b-col>
                    <b-col md="6">
                      <label>Tanggal Kembali</label>
                      <br>
                      <date-picker
                        :firstDayOfWeek="1"
                        v-model="model.return_date"
                        name="return_date"
                        :lang="lang"
                        :format="formats"
                        value-type="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        ></date-picker>
                    </b-col>
                  </b-row>
                </b-form>
                <b-form @submit.prevent="handleSubmit(filter)" v-else-if="role_name == 'Admin Sppd' && scope== 'UPT'">
                  <b-row>
                    <b-col md="6">
                      <label>NIPPOS</label>
                      <multiselect 
                        v-model="selectNIPPOS"
                        name="nippos"
                        label="nippos"
                        track-by="nippos" 
                        placeholder="Cari NIPPOS"
                        :options="nippos"
                        class="single-select filter-laporan-sppd"
                        :max-height="600"
                        @input="selectedSPPD(selectNIPPOS.nippos)">
                        <span slot="noResult">Maaf NIPPOS tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>No SPPD</label>
                      <v-select v-model="model.sppd_number" :options="sppd" :reduce="(item) => item.sppd_number" label="sppd_number" class="key-filter" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <label>Mitra Transportasi</label>
                      <multiselect 
                        v-model="selectedMitraTransportation"
                        name="id"
                        label="agen_name"
                        track-by="id" 
                        placeholder="Cari Mitra Transportasi"
                        :options="mitraTransportasi"
                        class="single-select filter-laporan-sppd"
                        :max-height="600">
                        <span slot="noResult">Maaf mitra transportasi tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                    <b-col md="6">
                      <label>Mitra Akomodasi</label>
                      <multiselect 
                        v-model="selectedAccomodation"
                        name="id"
                        label="agen_name"
                        track-by="id" 
                        placeholder="Cari Mitra Akomodasi"
                        :options="mitraAkomodasi"
                        class="single-select filter-laporan-sppd"
                        :max-height="600">
                        <span slot="noResult">Maaf mitra akomodasi tidak ditemukan</span>
                      </multiselect>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="6">
                      <label>Tanggal Berangkat</label>
                      <br>
                      <date-picker
                        :firstDayOfWeek="1"
                        v-model="model.departure_date"
                        name="departure_date"
                        :lang="lang"
                        :format="formats"
                        value-type="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        ></date-picker>
                    </b-col>
                    <b-col md="6">
                      <label>Tanggal Kembali</label>
                      <br>
                      <date-picker
                        :firstDayOfWeek="1"
                        v-model="model.return_date"
                        name="return_date"
                        :lang="lang"
                        :format="formats"
                        value-type="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        ></date-picker>
                    </b-col>
                  </b-row>
                </b-form>
                <b-form @submit.prevent="handleSubmit(filter)" v-else-if="role_name == 'Pegawai'">
                  <b-row class="mb-1">
                    <b-col md="6">
                      <label>No SPPD</label>
                      <v-select v-model="model.sppd_number" :options="sppdNIP" :reduce="(item) => item.sppd_number" label="sppd_number" class="key-filter" />
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col md="6">
                      <label>Tanggal Berangkat</label>
                      <br>
                      <date-picker
                        :firstDayOfWeek="1"
                        v-model="model.departure_date"
                        name="departure_date"
                        :lang="lang"
                        :format="formats"
                        value-type="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        ></date-picker>
                    </b-col>
                    <b-col md="6">
                      <label>Tanggal Kembali</label>
                      <br>
                      <date-picker
                        :firstDayOfWeek="1"
                        v-model="model.return_date"
                        name="return_date"
                        :lang="lang"
                        :format="formats"
                        value-type="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                        ></date-picker>
                    </b-col>
                  </b-row>
                </b-form>
              </div>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-secondary"
                  size="md"
                  class="float-right"
                  @click="reset()"
                >
                  Reset
                </b-button>
                <b-button
                  variant="primary"
                  size="md"
                  class="float-right btn btn-primary mr-1"
                  @click="filter()"
                >
                  Submit
                </b-button>
              </div>
            </template>
            </b-modal>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">
                Tidak Memiliki Akses
            </h2>
            <p class="mb-2">
                Anda tidak memiliki akses pada halaman ini
            </p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}
</style>

<script>
import { BForm, BRow, BCol, BTable, BCard, BButton, BButtonGroup, BInputGroup, BFormInput, BInputGroupAppend, BCardBody, BFormGroup, BFormSelect, BPagination, VBPopover, BFormCheckbox, BFormRadioGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import TableFe from "@/views/table/bs-table/TableFE";
import axios from "@axios";
import Ripple from "vue-ripple-directive";
import Multiselect from 'vue-multiselect';

export default {
  name: "SppdList",
  components: {
    BForm,
    BRow,
    BCol,
    BTable,
    BCard,
    TableFe,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardBody,
    BFormGroup,
    BFormSelect,
    vSelect,
    BPagination,
    VBPopover,
    BFormCheckbox,
    Multiselect,
    BFormRadioGroup
  },
  directives: {
    Ripple,
    "b-popover": VBPopover
  },
  data() {
    return {
      config: {
        api: "/reports",
        subdir: '/misc/sub-direktorat',
      },
      show: false,
      search: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      from: 1,
      to: 10,
      sortBy: 'id',
      pageOptions: [1, 10, 25, 50, 100],
      fields: [
        "no",
        { key: "sppd_number", label: "No SPPD" },
        { key: "name", label: "NIPPOS / Nama" },
        { key: "facility_name", label: "Jenis Fasilitas" },
        { key: "daily_trip_money", label: "Uang Harian" },
        { key: "total_transportation", label: "Transportasi" },
        { key: "total_accomodation", label: "Akomodasi" },
        { key: "total", label: "Jumlah" },
        { key: "pph21", label: "Pph21" },
        { key: "period", label: "Periode" },
        { key: "day_remainder", label: "Sisa" },
        { key: "mitra_accomodation_name", label: "Mitra Akomodasi" },
        { key: "mitra_transportation_name", label: "Mitra Transportasi" }
      ],
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      userData: JSON.parse(localStorage.getItem('userData')),
      userDataRegional: JSON.parse(localStorage.getItem('userData')).regional,
      report: [],
      regional: [],
      upt: [],
      kcu: [],
      kc: [],
      uptSppd: [],
      nippos: [],
      sppd: [],
      sppdNIP: [],
      mitraTransportasi: [],
      mitraAkomodasi: [],
      selectedProject: '',
      selectProjectOption: [
        { text: 'Project', value: 'project'},
        { text: 'Non Project', value: 'non project'},
      ],
      subdirData: null,
      subdirLists: [],
      key_filter: [
        { key: "nippos", label: "NIPPOS" },
        { key: "sppd_number", label: "No SPPD" },
        { key: "name", label: "Nama" },
        { key: "departure_date", label: "Tanggal Berangkat" },
        { key: "return_date", label: "Tanggal Kembali" },
      ],
      model: {
        nippos : '',
        sppd_number: '',
        name: '',
        departure_date: '',
        return_date: '',
        regional: '',
        company: '',
        mitra_accomodation: '',
        mitra_transportation: '',
        code_subdirektorat: '',
        name_subdirektorat: '',
        project_type: '',
      },
      selectRegional: [],
      selectedAccomodation: "",
      selectedMitraTransportation: "",
      selectUPT: '',
      selectKCU: [],
      selectKC: [],
      selectNIPPOS: "",
      role_name: JSON.parse(localStorage.getItem('userData')).role_name,
      scope: JSON.parse(localStorage.getItem('userData')).scope,
      relation: 'KCU',
      filterRelation: [
        'PUSAT',
         'KCU',
         'KC',
         'KCP',
         'AREA'
      ],
      nextTodoId: 2,
      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
        busy: false,
      },
    };
  },
  watch: {
    search: function () {
      this.$refs.tableFe.refresh()
    },
  },
  mounted() {
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
    //_.getReports()
    _.getSubdirList()
    _.getRegional()
    _.getNIPPOS()
    _.getMitraAkomodasi()
    _.getMitraTransportasi()
    if (_.role_name != 'Admin Nasional') {
      _.getUPT()
    }
  },
  methods: {
    disabledDate(date) {
      return new Date() < date;
    },
    selectedRelation(data) {
      let _ = this
      _.relation = data
      if (_.role_name == 'Admin Nasional') {
        localStorage.setItem('relation', data)
        _.selectRegional = []
        _.selectUPT = ''
        _.selectKCU = ''
        _.selectKC = ''
        _.selectNIPPOS = ''
        _.selectedAccomodation = ''
        _.selectedMitraTransportation = ''
        _.model.regional = ''
        _.model.company = ''
        _.model.nippos = ''
        _.model.sppd_number = ''
        _.model.name = ''
        _.model.departure_date = ''
        _.model.return_date = ''
      } else {
        _.selectUPT = ''
        _.selectKCU = '',
        _.selectKC = ''
        _.selectNIPPOS = ''
        _.selectedAccomodation = ''
        _.selectedMitraTransportation = ''
        _.model.regional = ''
        _.model.company = ''
        _.model.nippos = ''
        _.model.sppd_number = ''
        _.model.name = ''
        _.model.departure_date = ''
        _.model.return_date = ''
        _.getUPT()
      }
    },
    getItems(ctx, callback) {
      const _ = this
      _.busy = true;
      

      let endpoint = `${_.config.api}?per_page=${_.perPage}&page=${_.currentPage}`
      if (_.search != "") {
        endpoint += `&key=${_.search}`
      }
      if (_.selectNIPPOS.nippos) {
        endpoint += `&nippos=${_.selectNIPPOS.nippos}`
      }
      if (_.model.sppd_number) {
        endpoint += `&sppd_number=${_.model.sppd_number}`
      }
      if (_.model.name) {
        endpoint += `&name=${_.model.name}`
      }
      if (_.selectedMitraTransportation.id) {
        endpoint += `&mitra_transportation=${_.selectedMitraTransportation.id}`
      }
      if (_.selectedAccomodation.id) {
        endpoint += `&mitra_accomodation=${_.selectedAccomodation.id}`
      }
      if (_.model.departure_date) {
        endpoint += `&departure_date=${_.model.departure_date}`
      }
      if (_.model.return_date) {
        endpoint += `&return_date=${_.model.return_date}`
      }
      if (_.selectRegional.idwilayah != undefined) {
        endpoint += `&regional=${_.selectRegional.idwilayah}`
      }
      if (_.model.code_subdirektorat != '') { 
        endpoint += `&subdir=${_.model.code_subdirektorat}`
      }
      if (_.model.selectedProject) {
        endpoint += `&project_type=${_.model.selectedProject}`
      }
      if (_.selectKCU.nopend) {
        endpoint += `&nopend=${_.selectKCU.nopend}`
      }
      // if (_.selectKCU == []) {
      //   endpoint += `&nopend=[]`
      // }
      if (_.selectKC.nopend) {
        endpoint += `&nopend=${_.selectKC.nopend}`
      }
      // if (_.selectKC == []) {
      //   endpoint += `&nopend=[]`
      // }
      // console.log(endpoint);
      
      if (_.role_name != 'Admin Nasional') {
        axios
          .get(endpoint)
          .then(resp => {
            const consume = resp.data.data
            _.data = resp.data.data
            _.perPage = consume.per_page
            _.currentPage = consume.current_page
            _.totalRows = consume.total
            _.from = consume.from ?? 0
            _.to = consume.to ?? 0
            callback(consume.data || [])
          })
          .catch(error => {
            console.error(error)

            var message = "";
            if(typeof error.response.data.message === 'object'){
              message = error.response.data.message.errors[0].msg
            } else {
              message = error.response.data.message
            }
            this.makeToast('danger', "Terjadi Kesalahan", message)
          })
          .finally(() => {
            _.busy = false;
          });
        return null
      } else {
        if (_.model.code_subdirektorat || _.model.selectedProject || _.selectNIPPOS.nippos || _.model.sppd_number || _.model.name || _.selectedMitraTransportation.id  || _.selectedAccomodation.id || _.model.departure_date || _.model.return_date || _.selectRegional.idwilayah || _.selectUPT.namaktr || _.search) {
          axios
            .get(endpoint)
            .then(resp => {
              const consume = resp.data.data
              _.data = resp.data.data
              _.perPage = consume.per_page
              _.currentPage = consume.current_page
              _.totalRows = consume.total
              _.from = consume.from ?? 0
              _.to = consume.to ?? 0
              callback(consume.data || [])
            })
            .catch(error => {
              console.error(error)

              var message = "";
              if(typeof error.response.data.message === 'object'){
                message = error.response.data.message.errors[0].msg
              } else {
                message = error.response.data.message
              }
              this.makeToast('danger', "Terjadi Kesalahan", message)
            })
            .finally(() => {
              _.busy = false;
            });
          return null
        } else {
          let endpoint_null = `${_.config.api}?per_page=${_.perPage}&page=${_.currentPage}`
          axios
            .get(endpoint_null)
            .then(resp => {
              const consume = resp.data.data
              _.data = resp.data.data
              _.perPage = consume.per_page
              _.currentPage = consume.current_page
              _.totalRows = consume.total
              _.from = consume.from ?? 0
              _.to = consume.to ?? 0
              callback(consume.data || [])
            })
            .catch(error => {
              console.error(error)

              var message = "";
              if(typeof error.response.data.message === 'object'){
                message = error.response.data.message.errors[0].msg
              } else {
                message = error.response.data.message
              }
              this.makeToast('danger', "Terjadi Kesalahan", message)
            })
            .finally(() => {
              _.busy = false;
            });
            return null
        }
      }
    },
    handleSearch() {
      this.$refs.tableFe.refresh()
    },
    changePerPage() {
      const _ = this
      _.$refs.tableFe.refresh()
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
    exportData() {
      let _ = this;
      if(_.totalRows > 0){
        let today = new Date();
        let dateNow = today.toLocaleDateString('en-GB', {
          day: 'numeric', month: 'numeric', year: 'numeric'
        }).replace(/[^\d,.-]/g,'-');     
        let timeNow = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds(); 
        let endpoint = `${_.config.api + "/export"}?key=${_.search}`
        if (_.selectNIPPOS.nippos) {
          endpoint += `&nippos=${_.selectNIPPOS.nippos}`
        }
        if (_.model.sppd_number) {
          endpoint += `&sppd_number=${_.model.sppd_number}`
        }
        if (_.model.name) {
          endpoint += `&name=${_.model.name}`
        }
        if (_.selectedMitraTransportation.id) {
          endpoint += `&mitra_transportation=${_.selectedMitraTransportation.id}`
        }
        if (_.selectedAccomodation.id) {
          endpoint += `&mitra_accomodation=${_.selectedAccomodation.id}`
        }
        if (_.model.departure_date) {
          endpoint += `&departure_date=${_.model.departure_date}`
        }
        if (_.model.return_date) {
          endpoint += `&return_date=${_.model.return_date}`
        }
        if (_.selectRegional.idwilayah) {
          endpoint += `&regional=${_.selectRegional.idwilayah}`
        }
        if (_.selectKCU.nopend) {
          endpoint += `&nopend=${_.selectKCU.nopend}`
        }   
        if (_.selectKC.nopend) {
          endpoint += `&nopend=${_.selectKC.nopend}`
        }   
        axios({
            url: endpoint,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'laporan-'+dateNow+'-'+timeNow+'.xls');
            document.body.appendChild(fileLink);
            
            fileLink.click();
        });
      }
    },
    getReports(){
        let _ = this
        let uri = "/misc/report"
        axios.get(uri+ '?company=' + _.userData.nopend).then(resp => {
            _.report = resp.data.data
        }).catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    changeSubdir(){
      // console.log(this.subdirData.kodebagian_child);
      
      this.model.code_subdirektorat = this.subdirData.kodebagian_child;
      this.model.name_subdirektorat = this.subdirData.namabagian_child;
    },
    getSubdirList() {
      axios.get(this.config.subdir).then(response => {
        this.subdirLists = response.data.data
      })
      .catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    getRegional(){
        let _ = this
        let uri = "/misc/regionals"
        axios.get(uri).then(resp => {
          _.regional = resp.data.data
          _.regional.unshift({
                idwilayah: ["99", "03", "02", "01"],
                nmwilayah: "SEMUA REGIONAL",
            })
        }).catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    selectedUPT(id){
        let _ = this
        let uri = "/misc/companies/level"
        axios.get(uri+ '?region=' + id + '&relation=KCU').then(resp => {
            _.kcu = resp.data.data
            _.kcu.unshift({
                nopend: [],
                namaktr: "SEMUA KCU",
            })
            _.selectKCU= ''
            _.selectKCU.nopend = ''
        }).catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
        axios.get(uri+ '?region=' + id + '&relation=KC').then(resp => {
            _.kc = resp.data.data
            _.kc.unshift({
                nopend: [],
                namaktr: "SEMUA KC",
            })
            _.selectKC= ''
            _.selectKC.nopend = ''
        }).catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    selectedNIPPOSKCU(id){
        let _ = this
        _.selectKC = ''
        let uri = "/misc/employees"
        axios.get(uri+ '?company=' + id).then(resp => {
            _.nippos = resp.data.data
            _.selectNIPPOS=''
            _.selectNIPPOS.nippos=''
        }).catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    selectedNIPPOS(id){
      let _ = this
        _.selectKCU = ''
        let uri = "/misc/employees"
        axios.get(uri+ '?company=' + id).then(resp => {
          _.nippos = resp.data.data
            _.selectNIPPOS=''
            _.selectNIPPOS.nippos=''
        }).catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    selectedSPPD(id){
      let _ = this
      let uri = "/misc/sppds"
      axios.get(uri+ '?nippos=' + id).then(resp => {
          _.sppd = resp.data.data
          _.model.sppd_number=''
      }).catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    getUPT(){
      let _ = this
      let uri = "/misc/companies/level"
      axios.get(uri+ '?region=' + _.userDataRegional + '&relation=KCU').then(resp => {
          _.kcu = resp.data.data
          _.kcu.unshift({
              nopend: [],
              namaktr: "SEMUA KCU",
          })
          _.selectKCU= ''
          _.selectKCU.nopend = ''
      }).catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
      axios.get(uri+ '?region=' + _.userDataRegional + '&relation=KC').then(resp => {
          _.kc = resp.data.data
          _.kc.unshift({
              nopend: [],
              namaktr: "SEMUA KC",
          })
          _.selectKC= ''
          _.selectKC.nopend = ''
      }).catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    getNIPPOS(){
      let _ = this
      let uri = "/misc/employees"
      axios.get(uri+ '?company=' + _.userData.nopend).then(resp => {
          _.nippos = resp.data.data
      }).catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    getSPPD(){
      let _ = this
      let uri = "/misc/sppds"
      axios.get(uri+ '?nippos=' + _.userData.nippos).then(resp => {
          _.sppdNIP = resp.data.data
      }).catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    getMitraTransportasi(){
      let _ = this
      let uri = "/misc/mitras"
      axios.get(uri).then(resp => {
          _.mitraTransportasi = resp.data.data
          _.mitraTransportasi.unshift({
              id: [],
              agen_name: "Semua Mitra Transportasi",
          })
      }).catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    getMitraAkomodasi(){
      let _ = this
      let uri = "/misc/mitras"
      axios.get(uri).then(resp => {
          _.mitraAkomodasi = resp.data.data
          _.mitraAkomodasi.unshift({
              id: [],
              agen_name: "Semua Mitra Akomodasi",
          })
      }).catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    filter() {
      if (this.selectRegional == null) {
        this.selectRegional = ''
      }
      if (this.selectKCU == null) {
        this.selectKCU = ''
      }
      if (this.selectKC == null) {
        this.selectKC = ''
      }
      if (this.selectNIPPOS == null) {
        this.selectNIPPOS = ''
      }
      if (this.model.regional == null) {
        this.model.regional = ''
      }
      if (this.model.company == null) {
        this.model.company = ''
      }
      if (this.model.nippos == null) {
        this.model.nippos = ''
      }
      if (this.model.sppd_number == null) {
        this.model.sppd_number = ''
      }
      if (this.selectedAccomodation == null) {
        this.selectedAccomodation = ''
      }
      if (this.selectedMitraTransportation == null) {
        this.selectedMitraTransportation = ''
      }
      if (this.code_subdirektorat == null) {
        this.code_subdirektorat = ''
        this.name_subdirektorat = ''
      }
      if (this.project_type == null) {
        this.project_type = ''
      }
      this.show = false
      this.$refs.tableFe.refresh()
    },
    reset() {
      this.selectRegional = '',
      this.selectUPT = '',
      this.selectKCU = '',
      this.selectKC = '',
      this.selectNIPPOS = '',
      this.model.regional = '',
      this.model.company = '',
      this.model.nippos = '',
      this.model.sppd_number = '',
      this.model.name = '',
      this.model.departure_date = '',
      this.model.return_date = '',
      this.selectedAccomodation = '',
      this.selectedMitraTransportation = '',
      this.selectedMitraTransportation = '',
      this.selectedMitraTransportation = '',
      this.model.code_subdirektorat = '',
      this.model.name_subdirektorat = '',
      this.subdirData = null,
      this.model.project_type = '',
      this.show = false
      this.$refs.tableFe.refresh()
    },
  },
};
</script>

<style>
.b-popover{
  font-size: 10px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>